<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { useRoute } from 'vue-router'

  import { useQuery } from '@tanstack/vue-query'

  import { useIsMobile } from '@algorh/shared'

  import { AlgDot, AlgIcon } from '@algorh/ui'

  import { UserPermissions } from '@/api/interfaces/User'

  import { SchedulingSubdivisionsApiService } from '@/api/scheduling'

  import UserInfos from '@/components/UserInfos.vue'

  import { useAppInit } from '@/composables/useAppInit'
  import { useConfigs } from '@/composables/useConfigs'

  import { version } from '../../../../../package.json'

  import NavigationDisclosure from './NavigationDisclosure.vue'

  const emit = defineEmits(['close', 'open-account-modal'])

  // Composables
  const { t } = useI18n()

  const route = useRoute()
  const isMobile = useIsMobile()

  const { me, permissions } = useAppInit()

  const {
    calendar,
    common: commonConfig,
    hyperPeriodEnabled,
    criticalPeriodEnabled,
    noticeEnabled,
    documentationEnabled,
    leaveBalancesImportEnabled,
    notificationsEnabled,
    schedulingEnabled,
    rgpdEnabled,
    absencesEnabled,
    isLoadingConfig,
  } = useConfigs()

  // Data
  const matchedNames = route.matched.map((r) => r.name)
  const isSubdivisionOpen = matchedNames.includes('scheduling')
  const isParametersOpen = matchedNames.includes('settings')
  const isAbsencesOpen = matchedNames.includes('absences')

  // Methods
  function can(requestedPermissions: UserPermissions[]) {
    return requestedPermissions.some((permission) => permissions.value?.[permission])
  }

  function active(routes: string[]) {
    return routes.includes(route.name as string)
  }

  function closeMobileMenu() {
    if (isMobile.value) {
      emit('close')
    }
  }

  const { data: subdivisions } = useQuery({
    enabled: schedulingEnabled,
    queryKey: ['subdivision'],
    queryFn: SchedulingSubdivisionsApiService.getSubdivisions,
  })

</script>

<template>
  <div class="navigation-menu">
    <div class="user-infos-wrapper">
      <UserInfos
        :user="me?.data"
        @click="() => emit('open-account-modal')"
      />
    </div>
    <!-- Planning -->
    <div
      v-if="!isLoadingConfig"
      class="nav-links"
    >
      <RouterLink
        v-if="can([UserPermissions.SEE_GLOBAL_PLANNING])"
        class="nav-link"
        :class="{
          active: active([
            'global-planning'
          ])
        }"
        :to="{ name: 'global-planning'}"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="calendar-today"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('scheduling.Global planning') }}
        </span>
      </RouterLink>
      <RouterLink
        v-if="can([UserPermissions.ACCESS_CALENDAR])"
        class="nav-link"
        :class="{
          active: active([
            'calendar',
            'calendar-date'
          ])
        }"
        :to="{ name: 'calendar' }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="today"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('calendar.My planning') }}
        </span>
      </RouterLink>
      <!-- Notifications -->
      <RouterLink
        v-if="permissions?.access_calendar && notificationsEnabled"
        class="nav-link"
        :class="{
          active: active([
            'notifications'
          ])
        }"
        :to="{ name: 'notifications' }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="notifications"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('common.Notifications') }}
        </span>
      </RouterLink>
      <!-- Projects -->
      <RouterLink
        v-if="can([UserPermissions.MANAGE_PROJECTS])"
        class="nav-link"
        :class="{
          active: active([
            'projects',
            'project-list',
            'project-activities',
            'project-rules',
            'project-ambitions'
          ])
        }"
        :to="{ name: 'projects' }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="design-services"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('projects.Projects') }}
        </span>
      </RouterLink>
      <!-- Planification -->
      <template v-if="schedulingEnabled">
        <template v-if="can([UserPermissions.ACCESS_SCHEDULING])">
          <NavigationDisclosure :open="isSubdivisionOpen">
            <template #summary>
              <div class="nav-link">
                <AlgIcon
                  class="nav-link-icon"
                  name="tune"
                  size="s"
                />
                <span class="nav-link-label">
                  {{ t('scheduling.Scheduling') }}
                </span>
              </div>
            </template>
            <template #details>
              <template v-if="subdivisions?.data?.length">
                <RouterLink
                  v-for="subdivision in subdivisions.data"
                  :key="subdivision.id"
                  class="nav-link sub-link"
                  :class="{
                    active: subdivision.id.toString() === route.params.subdivisionId
                  }"
                  :to="{
                    name: 'production',
                    params: {
                      subdivisionId: subdivision.id.toString(),
                      date: route.params.date
                    }
                  }"
                  @click="closeMobileMenu"
                >
                  <span class="nav-link-label">
                    <AlgDot
                      size="xs"
                      :color="subdivision.project.color"
                    />
                    {{ subdivision.name }}
                  </span>
                </RouterLink>
              </template>
              <div
                v-else
                class="no-data"
              >
                <AlgIcon
                  name="warning"
                  size="s"
                />
                {{ t('subdivisions.No subdivision') }}
              </div>
            </template>
          </NavigationDisclosure>
        </template>
        <!-- Statistics -->
        <RouterLink
          v-if="can([UserPermissions.ACCESS_PROJECT_STATISTICS, UserPermissions.ACCESS_STATISTICS])"
          class="nav-link"
          :class="{
            active: active([
              'statistics'
            ])
          }"
          :to="{ name: 'statistics' }"
          @click="closeMobileMenu"
        >
          <AlgIcon
            class="nav-link-icon"
            name="data-usage"
            size="s"
          />
          <span class="nav-link-label">
            {{ t('statistics.Statistics') }}
          </span>
        </RouterLink>
      </template>
      <!-- Absences -->
      <template v-if="absencesEnabled">
        <NavigationDisclosure :open="isAbsencesOpen">
          <template #summary>
            <div class="nav-link">
              <AlgIcon
                class="nav-link-icon"
                name="person-off"
                size="s"
              />
              <span class="nav-link-label">
                {{ t('absences.Absences') }}
              </span>
            </div>
          </template>
          <template #details>
            <RouterLink
              v-if="can([UserPermissions.SEE_ABSENCES])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-my-teams-absences'
                ])
              }"
              :to="{ name: 'absences-my-teams-absences' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('common.Dashboard') }}
              </span>
            </RouterLink>
            <RouterLink
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-leave-request'
                ])
              }"
              :to="{ name: 'absences-leave-request' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('absences.Request an absence') }}
              </span>
            </RouterLink>
            <RouterLink
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-my-absences',
                  'absences-my-absence-details'
                ])
              }"
              :to="{ name: 'absences-my-absences' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('absences.My absences') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([
                UserPermissions.ACCEPT_OR_DENY_PENDING_ABSENCES
              ])
              "
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-validations',
                  'absences-validations-list',
                  'absences-validation-details'
                ])
              }"
              :to="{ name: 'absences-validations' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('absences.Absence validation') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="criticalPeriodEnabled &&
                can([
                  UserPermissions.ACCESS_ABSENCES_ON_CRITICAL_PERIODS,
                ])
              "
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-critical-period-absences'
                ])
              }"
              :to="{ name: 'absences-critical-period-absences' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('absences.Critical period absences') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([UserPermissions.CREATE_ABSENCES_FOR_OTHER])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-employees',
                  'absences-employees-list',
                  'absences-employee-details'
                ])
              }"
              :to="{ name: 'absences-employees' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('absences.Absence of an employee') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([UserPermissions.ACCESS_VALIDATION_PROCESSES])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'absences-validation-processes',
                  'absences-validation-process-list',
                  'absences-validation-process',
                  'absences-validation-process-create'
                ])
              }"
              :to="{ name: 'absences-validation-processes' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('absences.Validation management') }}
              </span>
            </RouterLink>
            <template v-if="leaveBalancesImportEnabled">
              <RouterLink
                v-if="can([UserPermissions.IMPORT_LEAVE_BALANCES])"
                class="nav-link sub-link"
                :class="{
                  active: active([
                    'absences-import-leave-balances'
                  ])
                }"
                :to="{ name: 'absences-import-leave-balances' }"
                @click="closeMobileMenu"
              >
                <span class="nav-link-label">
                  {{ t('absences.Import leave balances') }}
                </span>
              </RouterLink>
            </template>
          </template>
        </NavigationDisclosure>
      </template>
      <!-- Stock exchange -->
      <RouterLink
        v-if="calendar?.data?.stock_exchanges"
        class="nav-link"
        :class="{
          active: active([
            'stock-exchange',
            'stock-exchange-list',
            'stock-exchange-day-off-list',
            'stock-exchange-day-off-requests-and-offers',
            'stock-exchange-slot-list',
            'stock-exchange-slot-requests-and-offers',
            'stock-exchange-heavy-load-week-list',
            'stock-exchange-heavy-load-week-requests-and-offers'
          ])
        }"
        :to="{ name: 'stock-exchange'}"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="storefront"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('stockExchange.Stock exchange') }}
        </span>
      </RouterLink>
      <!-- Settings -->
      <template
        v-if="can([
          UserPermissions.ACCESS_HYPER_PERIODS,
          UserPermissions.MANAGE_ACTIVITIES,
          UserPermissions.MANAGE_RULES_AND_AMBITIONS,
          UserPermissions.MANAGE_USERS,
          UserPermissions.MANAGE_ROLES,
          UserPermissions.ACCESS_WORK_CYCLES,
          UserPermissions.MANAGE_WORK_CYCLES,
          UserPermissions.ACCESS_TAGS
        ])
        "
      >
        <NavigationDisclosure :open="isParametersOpen">
          <template #summary>
            <div class="nav-link">
              <AlgIcon
                class="nav-link-icon"
                name="settings"
                size="s"
              />
              <span class="nav-link-label">
                {{ t('common.Parameters') }}
              </span>
            </div>
          </template>
          <template #details>
            <RouterLink
              v-if="hyperPeriodEnabled && can([UserPermissions.ACCESS_HYPER_PERIODS])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'settings-hyper-periods',
                  'settings-hyper-period'
                ])
              }"
              :to="{ name: 'settings-hyper-periods' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('hyperPeriods.Hyper periods') }}
              </span>
            </RouterLink>
            <template v-if="schedulingEnabled">
              <RouterLink
                v-if="can([UserPermissions.MANAGE_ACTIVITIES])"
                class="nav-link sub-link"
                :class="{
                  active: active([
                    'settings-activities'
                  ])
                }"
                :to="{ name: 'settings-activities' }"
                @click="closeMobileMenu"
              >
                <span class="nav-link-label">
                  {{ t('activities.Activities') }}
                </span>
              </RouterLink>
              <RouterLink
                v-if="can([UserPermissions.MANAGE_RULES_AND_AMBITIONS])"
                class="nav-link sub-link"
                :class="{
                  active: active([
                    'settings-rules',
                    'settings-ambitions'
                  ])
                }"
                :to="{ name: 'settings-rules' }"
              >
                <span class="nav-link-label">
                  {{ t('rules.Rules and Ambitions') }}
                </span>
              </RouterLink>
            </template>
            <RouterLink
              v-if="can([UserPermissions.ACCESS_TAGS])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'settings-tags'
                ])
              }"
              :to="{ name: 'settings-tags' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('tags.Tags') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([UserPermissions.MANAGE_USERS])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'settings-users',
                  'settings-users-list',
                  'settings-users-create',
                  'settings-users-update'
                ])
              }"
              :to="{ name: 'settings-users' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('users.User accounts') }}
              </span>
            </RouterLink>
            <template v-if="schedulingEnabled && commonConfig?.data?.work_cycle">
              <RouterLink
                v-if="can([UserPermissions.ACCESS_WORK_CYCLES])"
                class="nav-link sub-link"
                :class="{
                  active: active([
                    'settings-work-cycles',
                    'settings-work-cycles-list',
                    'settings-work-cycles-create',
                    'settings-work-cycles-update',
                    'settings-work-cycles-show'
                  ])
                }"
                :to="{ name: 'settings-work-cycles' }"
                @click="closeMobileMenu"
              >
                <span class="nav-link-label">
                  {{ t('workCycles.Work cycles') }}
                </span>
              </RouterLink>
            </template>
            <RouterLink
              v-if="can([UserPermissions.MANAGE_SUBDIVISIONS])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'settings-subdivisions'
                ])
              }"
              :to="{ name: 'settings-subdivisions' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('subdivisions.Subdivisions') }}
              </span>
            </RouterLink>
            <RouterLink
              v-if="can([UserPermissions.MANAGE_ROLES])"
              class="nav-link sub-link"
              :class="{
                active: active([
                  'settings-roles-permissions'
                ])
              }"
              :to="{ name: 'settings-roles-permissions' }"
              @click="closeMobileMenu"
            >
              <span class="nav-link-label">
                {{ t('common.Roles and permissions') }}
              </span>
            </RouterLink>
          </template>
        </NavigationDisclosure>
      </template>
      <template v-if="schedulingEnabled">
        <!-- Users -->
        <RouterLink
          v-if="can([
            UserPermissions.MANAGE_CUSTOMER_ADVISERS,
            UserPermissions.ACCESS_CUSTOMER_ADVISERS
          ])
          "
          class="nav-link"
          :class="{
            active: active([
              'users-customer-advisers',
              'users-customer-adviser',
              'users-supervisors',
              'users-customer-adviser-edit',
              'users-customer-advisers-list',
              'users-customer-adviser-details',
              'users-customer-adviser-others'
            ])
          }"
          :to="{ name: 'users-customer-advisers-list' }"
          @click="closeMobileMenu"
        >
          <AlgIcon
            class="nav-link-icon"
            name="group"
            size="s"
          />
          <span class="nav-link-label">
            {{ t('users.Users') }}
          </span>
        </RouterLink>
      </template>
      <template v-if="schedulingEnabled">
        <!-- Exports -->
        <RouterLink
          v-if="can([UserPermissions.ACCESS_EXPORTS])"
          class="nav-link"
          :class="{
            active: active([
              'exports',
            ])
          }"
          :to="{ name: 'exports' }"
          @click="closeMobileMenu"
        >
          <AlgIcon
            class="nav-link-icon"
            name="bar-chart-4-bars"
            size="s"
          />
          <span class="nav-link-label">
            {{ t('exports.Data export') }}
          </span>
        </RouterLink>
      </template>
      <div
        v-if="[rgpdEnabled, documentationEnabled, noticeEnabled].includes(true)"
        class="separator"
      />
      <!-- Documentation -->
      <RouterLink
        v-if="noticeEnabled"
        class="nav-link"
        :class="{
          active: active([
            'documentation-information-notice'
          ])
        }"
        :to="{ name: 'documentation-information-notice' }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="info"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('support.Information notice') }}
        </span>
      </RouterLink>
      <RouterLink
        v-if="documentationEnabled"
        class="nav-link"
        :class="{
          active: active([
            'documentation-online-support'
          ])
        }"
        :to="{ name: 'documentation-online-support' }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="support"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('support.Online support') }}
        </span>
      </RouterLink>
      <RouterLink
        v-if="rgpdEnabled"
        class="nav-link"
        :class="{
          active: active([
            'documentation-gdpr'
          ])
        }"
        :to="{ name: 'documentation-gdpr' }"
        @click="closeMobileMenu"
      >
        <AlgIcon
          class="nav-link-icon"
          name="policy"
          size="s"
        />
        <span class="nav-link-label">
          {{ t('support.GDPR') }}
        </span>
      </RouterLink>
    </div>
    <div class="navigation-menu-footer">
      {{
        t('common.© SoyHuCe {year} - Version {version}', {
          year: new Date().getFullYear().toString(),
          version: version
        })
      }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@property --gradient-value{
  inherits: false;
  initial-value: 0%;
  syntax: '<percentage>';
}

.navigation-menu {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-top: 64px;
  background-color: var(--alg-color-surface-primary);
  box-shadow: var(--alg-effect-shadow-s);

  .user-infos-wrapper {
    display: flex;
    height: 64px;
    box-sizing: border-box;
    align-items: center;
    padding: var(--alg-spacing-s) var(--alg-spacing-m);
    border-top: 1px solid var(--alg-color-surface-border);
    border-bottom: 1px solid var(--alg-color-surface-border);
    cursor: pointer;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    padding: var(--alg-spacing-s);
    padding-left: 14px;
    gap: var(--alg-spacing-s);
    overflow-y: auto;

    .nav-link {
      display: flex;
      box-sizing: border-box;
      flex: 1 1 auto;
      align-items: center;
      padding: var(--alg-spacing-s) var(--alg-spacing-m) var(--alg-spacing-s) var(--alg-spacing-s);
      border-radius: var(--alg-effect-radius-m);
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
      gap: var(--alg-spacing-s);
      transition-duration: var(--alg-transition-colors-duration);
      transition-property: color;
      transition-timing-function: var(--alg-transition-colors-timing-function);
      user-select: none;

      .nav-link-icon {
        color: var(--alg-color-icon-secondary);
      }

      .nav-link-label {
        display: flex;
        overflow: hidden;
        flex: 1 1 auto;
        align-items: center;
        color: var(--alg-color-text-primary);
        gap: var(--alg-spacing-s);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover,
      &.active {
        .nav-link-icon {
          color: var(--alg-color-icon-highlight);
        }

        .nav-link-label {
          color: var(--alg-color-text-highlight);
        }
      }

      &.active {
        background-color: var(--alg-color-surface-background);
      }

      .link-line {
        display: flex;
        align-items: center;
        gap: var(--alg-spacing-s);
      }

      &.sub-link {
        position: relative;
        box-sizing: border-box;
        padding: 0 var(--alg-spacing-s);
        margin-top: var(--alg-spacing-s);
        margin-left: var(--alg-spacing-s);

        .nav-link-label {
          padding: var(--alg-spacing-s);
          border-radius: var(--alg-effect-radius-m);
          margin-left: var(--alg-spacing-s);
          color: var(--alg-color-text-secondary);
          font-weight: var(--alg-font-weight-light);
        }

        &::before {
          position: absolute;
          top: 4px;
          bottom: 4px;
          width: 2px;
          border-radius: 2px;
          background: linear-gradient(var(--alg-color-surface-highlight) var(--gradient-value), var(--alg-color-surface-background) 0%);
          content: '';
          transition: --gradient-value 0.25s;
        }

        &:hover,
        &.active {
          background-color: var(--alg-color-transparent);

          .nav-link-icon {
            color: var(--alg-color-icon-highlight);
          }

          .nav-link-label {
            color: var(--alg-color-text-highlight);
          }
        }

        &.active {
          &::before {
            --gradient-value: 100%;
          }

          .nav-link-label {
            background-color: var(--alg-color-surface-background);
            font-weight: var(--alg-font-weight-bold);
          }
        }
      }
    }
  }

  .navigation-menu-footer {
    padding: var(--alg-spacing-m);
    margin-top: auto;
    color: var(--alg-color-text-light);
    font-size: var(--alg-font-size-s);
    font-weight: var(--alg-font-weight-light);
    text-align: center;
  }

  .separator {
    width: 64px;
    height: 1px;
    min-height: 1px;
    border-radius: 1px;
    margin: var(--alg-spacing-s) var(--alg-spacing-xl);
    background-color: var(--alg-color-surface-border);
  }

  .no-data {
    display: flex;
    align-items: center;
    padding: var(--alg-spacing-s);
    border-radius: var(--alg-effect-radius-m);
    background-color: var(--alg-color-surface-background);
    color: var(--alg-color-text-secondary);
    font-size: var(--alg-font-size-s);
    font-weight: var(--alg-font-weight-light);
    gap: var(--alg-spacing-xs);
  }
}
</style>
